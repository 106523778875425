@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@supports(padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

::-webkit-scrollbar-track{
  /*@apply bg-transparent rounded ;*/
}
::-webkit-scrollbar{
  /*@apply bg-blue-50 dark:bg-gray-700 w-2 rounded-full;*/
}
::-webkit-scrollbar-thumb{
  /*@apply bg-blue-500 dark:bg-yellow-400 rounded-full;*/
}
